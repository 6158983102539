import React, { useState, useMemo, useEffect } from "react"
import ReactMarkdown from 'react-markdown'
import {motion, useAnimation, useSpring} from "framer-motion";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Product, ProductVariant} from "../models/product"
import {FiMinus, FiPlus, FiShoppingCart} from "react-icons/fi";
import {useCart} from '../cart';
import {SelectOption} from "../components/select";
import {useIsMobile} from "../utils";
import {BlackLine} from "../components/line";
import { toast } from 'react-toastify';
import rehypeRaw from 'rehype-raw'
import {Radio } from "../components/radio";

const ProductImages = ({ coverUrl, isMobile, imageUrls = [] }) => {
    const [currentCover, setCurrentCover] = useState(coverUrl);
    const [subImages, setSubImages] = useState<string[]>(imageUrls);

    return (
    <div
        style={{
            ...(
                isMobile ? {
                    width: '100%',
                    display: 'flex'
                } : {
                    display: 'table-cell',
                    width: 500,
                }
            ),
            flexDirection: 'column',
            gap: 8
        }}
    >
        <img src={currentCover}
             style={{ width: '100%', maxHeight: 500, objectFit: "cover",}}
        />
        {
          imageUrls.length > 0 &&
            <div style={{ width: isMobile ? '100%' : 500, display: 'flex', gap: 8}}>
              {
                [coverUrl, ...subImages].map((v, i) => 
                <div style={{ flexGrow: 1, cursor: 'pointer', maxWidth: 120 }}
                  onClick={() => {
                    setCurrentCover(v);
                  }}
                >
                  <img src={v} style={{ width: '100%' }} />
                </div>
                )
              }
            </div>
        }
    </div>
  )
};
const ProductDescription = ({ description }) => (
  <div
      style={{
          maxWidth: '100%',
          padding: '1rem 0',
      }}
  >
    <ReactMarkdown 
      rehypePlugins={[rehypeRaw]}
      components={{
        p: (props) => <div style={{ margin: '1.625px 0px', textAlign: 'justify' }} {...props} />
      }}
      children={description} />
  </div>
);
const DetailImage = ({ url, isMobile }) => url ? (
  <div
      style={{
          ...(
              isMobile ? {
                  width: '100%',
              } : {
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  maxWidth: 500,
                  padding: '0 2rem',
              }
          ),
      }}
  >
      <img src={url}
           style={{ width: '100%', objectFit: "cover",}}
      />
  </div>
) : <div />;

const ProductTemplate = ({pageContext: {productData}}) => {
    const product = useMemo(() => Product.fromShopify(productData), [productData]);
  const [options, setOptions] = useState<string[]>(product.options.map((o) => o.values[0]
  ));
    const [spec, setSpec] = useState<ProductVariant | null>(null);
    const [beanOrBag, setBeanOrBag] = useState<string | null>('bean');
    const [quantity, setQuantity] = React.useState(1);
    const {addItem} = useCart();

    const [roast, setRoast] = useState<string | null>('淺焙');
    const [gear, setGear] = useState<string | null>('手沖');
    const [grind, setGrind] = useState<string | null>('不用磨');
    const [sour, setSour] = useState<number | null>('1');

    const {screenWidth} = useIsMobile();
    const isMobile = screenWidth < 1000;
    const  isSubscription = product.productType === '咖啡訂閱';

    const getVariants = () => 
      product?.variants.filter(({ name }) => name !== 'Default Title');
    const variants = getVariants();

    useEffect(() => {
      setSpec(options.reduce((r, o) => {
        return r.filter((v) => v.name.includes(o));
      }, variants)[0]);
    }, [options]);

    const Main = () => (
        <div
            style={{
            flexDirection: 'column',
            padding: isMobile ? '8px 0' : '0 2rem',
            alignItems: 'start',
            ...(
                isMobile ? {
                    display: 'flex',
                    maxWidth: '100%',
                } : {
                    verticalAlign: 'top',
                    display: 'table-cell',
                    maxWidth: 800,
                }
            ),
        }}>
                <span
                    style={{
                        fontSize: 28,
                        padding: '0.5rem 0',
                    }}
                >{product.name}</span>
      {product.engName && <div
                    style={{
                        padding: '0.5rem 0',
                    }}
                >{product.engName}</div>
      }
            <div style={{height: 8}}></div>
            <BlackLine/>
            <div style={{height: 8}}></div>
            <ProductDescription description={product.description} />
            <div style={{
                padding: '0.5rem 0',
                display: 'flex',
                alignItems: 'center',
            }}>
              <div style={{ minWidth: 72, marginRight: 16 }}>
                價格
              </div>
              <span style={{
                fontSize: 28,
                  padding: '0 0.5rem',
              }}>
                NT
                {
                  spec != null ? (
                          `$${spec.price}`
                      ) :
                      product.minPrice == product.maxPrice ? (
                          `$${product.minPrice}`
                      ) : (

                          `$${product.minPrice} - $${product.maxPrice}`
                      )
                }
              </span>
              {
                spec != null && spec.name !== 'Default Title' && (
                  `/ ${spec.name}`
                )
              }
            </div>
            {
              product.options.map((o) => <div
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '0.5rem 0',
                    alignItems: 'start'
                }}
              >
                <div style={{ minWidth: 88, marginTop: 8 }}>
                  {o.name}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}>
                    {
                        o.values.map((e) =>
                            <div style={{
                                margin: '8px'
                            }}>
                                <SelectOption
                                    value={e}
                                    text={e}
                                    selected={options.includes(e)}
                                    onClick={() => {
                                      setOptions(options.filter((i) => !o.values.includes(i)).concat(e));
                                    }}
                                />
                            </div>
                        )
                    }
                </div>
              </div>)
            }
            {
              isSubscription && <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <div
                  style={{
                      display: 'flex',
                      width: '100%',
                      padding: '0.5rem 0',
                      alignItems: 'center'
                  }}
                >
                  <div style={{
                    minWidth: 80,
                    marginRight: 16
                  }}>
                  沖煮方式
                  </div>
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}>
                    {
                      ['手沖', '濾壓壺', '愛樂壓', '濃縮'].map((v) =>
                        <SelectOption
                            value={v}
                            text={v}
                            selected={gear === v}
                            onClick={() => {
                              setGear(v);
                            }}
                        />
                      )
                    }
                  </div>
                </div>
                <div
                  style={{
                      display: 'flex',
                      width: '100%',
                      padding: '0.5rem 0',
                      alignItems: 'center'
                  }}
                >
                  <div style={{
                    minWidth: 80,
                    marginRight: 16
                  }}>
                    烘焙程度
                  </div>
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}>
                    {
                      ['淺焙', '淺中焙', '中焙', '中深焙', '深焙'].map((v) =>
                        <SelectOption
                            value={v}
                            text={v}
                            selected={roast === v}
                            onClick={() => {
                              setRoast(v);
                            }}
                        />
                      )
                    }
                  </div>
                </div>
                <div
                  style={{
                      display: 'flex',
                      width: '100%',
                      padding: '0.5rem 0',
                      alignItems: 'center'
                  }}
                >
                  <div style={{
                    minWidth: 80,
                    marginRight: 16
                  }}>
                    研磨程度
                  </div>
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}>
                    {
                      ['不用磨', '手沖', '愛樂壓', '濃縮'].map((v) =>
                        <SelectOption
                            value={v}
                            text={v}
                            selected={grind === v}
                            onClick={() => {
                              setGrind(v);
                            }}
                        />
                      )
                    }
                  </div>
                </div>
                <div
                  style={{
                      display: 'flex',
                      width: '100%',
                      padding: '0.5rem 0',
                      alignItems: 'center'
                  }}
                >
                  <div style={{
                    minWidth: 80,
                    marginRight: 16
                  }}>
                    酸度偏好
                  </div>
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}>
                    弱
                    {
                      ['1', '2', '3', '4', '5'].map((v) =>
                        <Radio
                            selected={sour}
                            onChange={() => {
                                setSour(v);
                            }}
                            value={v}
                        />
                      )
                    }
                    強
                  </div>
                </div>
              </div>
            }
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '0.5rem 0',
                    alignItems: 'center'
                }}
            >
                數量 &nbsp;
                <div style={{
                    border: '1px solid #ccc',
                    display: 'flex',
                    backgroundColor: '#fff',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                    }}
                         onClick={() => {
                             setQuantity(quantity + 1);
                         }}
                    >
                        <FiPlus/>
                    </div>
                    <div style={{
                        padding: '0.5rem',
                    }}>
                        {quantity}
                    </div>
                    <div style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                    }}
                         onClick={() => {
                             if (quantity > 1) {
                                 setQuantity(quantity - 1);
                             }
                         }}
                    >
                        <FiMinus/>
                    </div>
                </div>
                <div 
                  style={{
                    flexGrow: 1,
                    border: '1px solid #000',
                    marginLeft: '16px',
                    padding: '0.5rem',
                    textAlign: 'center',
                    cursor: 'pointer',
                      borderRadius: 4,
                  }}
                  onClick={() => {
                    toast('成功加入購物車' );
                    addItem([
                      {
                        variantId: spec!.id,
                        quantity,
                        ...(isSubscription ? {
                          customAttributes: [
                            {
                               key: '烘焙',
                              value: roast,
                            },
                            {
                               key: '研磨',
                              value: grind,
                            },
                            {
                               key: '酸度',
                              value: sour,
                            },
                            {
                               key: '沖煮',
                              value: gear,
                            },
                          ]
                        } : {})
                      }
                    ]);
                  }}
                >
                  加入購物車
                </div>
            </div>
        </div>
    );

    const DetailText = () => product.detailText ? (
      <div style={{
        ...(
            isMobile ? {
                display: 'flex',
                maxWidth: '100%',
                flexDirection: 'column',
            } : {
                verticalAlign: 'top',
                display: 'table-cell',
                maxWidth: 500,
            }
        ),
      }}>
        <ReactMarkdown 
          components={{
            p: (props) => <p style={{ textAlign: 'justify' }} {...props} />
          }}
          children={product.detailText} />
      </div>
      ) : <div />;



    const motionVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            },
        },
    };
    const motionProps = {
        animate: 'visible',
        initial: 'hidden',
        //exit: 'hidden',
        variants: motionVariants,
    }

  return (<div>
        <Seo title={product.name}/>
        <motion.div
            {...motionProps}
            style={{
                maxWidth: '88%',
                alignItems: 'start',
                margin: isMobile ? '24px auto' : '0 auto',
                padding: isMobile ? '0px' : '24px',
                justifyContent: 'center',
                ...(
                    isMobile ? {
                        display: 'flex',
                        flexDirection: 'column',
                    } : {
                        display: 'table',
                    }
                )
            }}
        >
            {
                isMobile ? [
                <ProductImages isMobile={isMobile} 
                      imageUrls={product.imageUrls}
                  coverUrl={product.coverUrl} />,
                    <Main/>
                ] : [
                    <Main/>,
                    <div style={{ width: 24}} />,
                    <ProductImages 
                      isMobile={isMobile} coverUrl={product.coverUrl} 
                      imageUrls={product.imageUrls}
                    />
                ]
            }
        </motion.div>
        <div style={{ height: 72 }} />
        <motion.div
            {...motionProps}
            style={{
                maxWidth: '88%',
                alignItems: 'start',
                margin: isMobile ? '24px auto' : '0 auto',
                justifyContent: 'center',
                ...(
                    isMobile ? {
                        display: 'flex',
                        flexDirection: 'column',
                    } : {
                        display: 'table',
                        paddingBottom: '24px',
                    }
                )
            }}
        >
            {
                isMobile ? [
                    <DetailImage isMobile={isMobile} url={product.detailImageUrl}/>,
                    <DetailText/>
                ] : [
                    <DetailImage isMobile={isMobile} url={product.detailImageUrl}/>,
                    <div style={{ width: 24}} />,
                    <DetailText/>
                ]
            }
        </motion.div>
    </div>);
}

export default ProductTemplate;
